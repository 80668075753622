<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <card title="Yoklama Çizelgesi">
      <v-date-picker
        v-model="date"
        full-width
        @change="modal = true"
        :landscape="$vuetify.breakpoint.smAndUp"
        :events="functionEvents"
        first-day-of-week="1"
        :weekday-format="date => moment(date).format('ddd')"
        locale="tr"
      ></v-date-picker>
    </card>
    <v-dialog slot="toolbar" v-model="modal" max-width="400px">
      <v-card>
        <v-card-title>
          <span class="headline" style="margin: 0 auto">Yoklama Al</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <b-row>
              <b-col cols="12">
                <div class="font-size-lg text-center">
                  <b>{{ student.name }} {{ student.surname }}</b> adlı
                  öğrencinin
                  <b>{{ moment(date).format('D MMMM YYYY') }}</b> tarihindeki
                  kursa gelme durumunu güncelliyorsunuz.
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="4">
                <v-btn color="success" block @click="setAttendance(1)"> GELDİ </v-btn>
              </b-col>
              <b-col md="4">
                <v-btn color="warning" block @click="setAttendance(2)">
                  İZİNLİ
                </v-btn>
              </b-col>
              <b-col md="4">
                <v-btn color="error" block @click="setAttendance(3)"> GELMEDİ </v-btn>
              </b-col>
            </b-row>
            <b-row>
              <b-col xs="12">
                <v-btn color="info" block @click="setAttendance(4)">
                  TEMİZLE
                </v-btn>
              </b-col>
            </b-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from 'moment';
import 'moment/locale/tr';

export default {
  props: ['student'],

  data() {
    return {
      dates: ['2020-5-28'],
      modal: false,
      date: null,
      attendances: []
    };
  },
  mounted() {
    this.getAttendanceList();
  },
  methods: {
    async setAttendance(status) {
      try {
        await this.axios.put(this.namespace + `/attendances`, {
          studentId: this.student.id,
          date: this.date,
          status
        });
        this.modal = false;
        this.getAttendanceList();

        this.$store.dispatch('setToast', {
          visible: true,
          text: 'Başarılı bir şekilde güncellendi!',
          color: 'success'
        });
      } catch (e) {
        this.$emit('result', {
          visible: true,
          text: 'Güncelleme esnasında hata meydana geldi!',
          color: 'danger'
        });
        console.log(e);
      }
    },
    functionEvents(date) {
      let color = false;
      this.attendances.map(attendance => {
        if (attendance.date === date) {
          switch (attendance.status) {
            case 1:
              color = ['green'];
              break;
            case 2:
              color = ['yellow'];
              break;
            case 3:
              color = ['red'];
              break;
          }
        }
      });
      return color;
    },
    async getAttendanceList() {
      try {
        const { data } = await this.axios.get(
          this.namespace + `/students/${this.student.id}/attendances`
        );

        this.attendances = data;
      } catch (e) {
        console.log(e);
      }
    },
    moment
  }
};
</script>
