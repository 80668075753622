<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-app>
    <v-col>
      <b-alert
        show
        variant="light"
        class="alert alert-custom alert-white alert-shadow fade show gutter-b"
      >
        <div class="alert-text">
          Öğrenci bilgilerini düzenleyebilirsiniz. Öğrenciye ait yoklama ve ders
          takip işlemelerini yapabilirsiniz.
        </div>
      </b-alert>
      <v-row>
        <v-col md="4">
          <card title="Öğrenci Bilgileri">
            <v-btn
              color="primary"
              outlined
              slot="toolbar"
              @click="$refs.studentUpdateModal.open(student)"
            >
              Düzenle
            </v-btn>
            <detail-list-item
              field="İsim"
              :value="student.name + ' ' + student.surname"
            />
            <detail-list-item field="TC Kimlik Numarası" :value="student.tc" />
            <detail-list-item field="Cinsiyet" :value="student.gender" />
            <detail-list-item
              field="Engel Durumu"
              :value="student.disabilityStatus"
            />
            <detail-list-item
              field="Eğitim Seviyesi"
              :value="student.educationLevel"
            />
            <detail-list-item
              field="Mezun Olduğu Sınıf"
              :value="student.graduatedFrom + ' .Sınıf'"
            />

            <detail-list-item
              field="Doğum Tarihi"
              :value="moment(student.dogumTarihi).format('D MMMM YYYY')"
            />
          </card>
        </v-col>
        <v-col md="8">
          <Yoklama :student="student" />
        </v-col>
        <v-col
          cols="12"
          md="4"
          v-for="missionGroup in missionGroups"
          :key="missionGroup.id"
        >
          <MissionCheck :student="student" :missionGroup="missionGroup" />
        </v-col>
      </v-row>
      <StudentUpdate
        ref="studentUpdateModal"
        @done="getStudent"
      ></StudentUpdate>
    </v-col>
  </v-app>
</template>

<script>
import { SET_BREADCRUMB } from '@/store/breadcrumbs.module';
import DetailListItem from '@/view/content/components/DetailListItem.vue';
import StudentUpdate from './StudentUpdate';
import MissionCheck from './MissionCheck';
import Yoklama from './Attendance';
import moment from 'moment';
import 'moment/locale/tr';

export default {
  props: ['studentId'],
  components: { DetailListItem, StudentUpdate, MissionCheck, Yoklama },

  data() {
    return {
      tarihler: [],
      date: null,
      student: {
        id: this.studentId
      },
      loading: true,
      missionGroups: []
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Camiler' },
      { title: 'Hocalar' },
      { title: 'Kurslar' },
      { title: 'Öğrenciler' },
      { title: 'Öğrenci Detay' }
    ]);
    this.getStudent();
  },
  methods: {
    async getStudent() {
      try {
        const { data } = await this.axios.get(
          this.namespace + '/students/' + this.studentId
        );

        this.student = data;

        this.getCourse(data.courseId);
      } catch (e) {
        console.log(e);
      }
    },
    async getCourse(courseId) {
      try {
        const { data } = await this.axios.get(
          this.namespace + '/courses/' + courseId + '/missionGroups'
        );

        this.missionGroups = data;
      } catch (e) {
        console.log(e);
      }
    },
    moment
  }
};
</script>
