<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <card :title="missionGroup.title" max-height="700">
    <div
      class="d-flex align-items-center mb-10"
      v-for="item in missions"
      :key="item.id"
    >
      <span class="bullet bullet-bar align-self-stretch bg-primary"></span>
      <label
        class="checkbox checkbox-lg checkbox-single checkbox-light-primary flex-shrink-0 m-0 mx-4"
      >
        <input
          type="checkbox"
          :checked="!!item.checkId"
          @input="updateState(item, $event.target.checked)"
        />
        <span></span>
      </label>
      <div class="d-flex flex-column flex-grow-1">
        <span
          class="text-dark-75 text-hover-primary font-weight-bold font-size-lg mb-1"
        >
          {{ item.title }}
        </span>
        <span class="text-muted font-weight-bold">
          {{ item.description }}
        </span>
      </div>
    </div>
  </card>
</template>

<script>
export default {
  props: ['student', 'missionGroup'],

  data() {
    return {
      missions: []
    };
  },
  mounted() {
    this.getMissionList();
  },
  methods: {
    async updateState(mission, checked) {
      try {
        if (checked) {
          const { data } = await this.axios.post(
            this.namespace + `/missionChecks`,
            {
              studentId: this.student.id,
              missionId: mission.id,
              missionGroupId: mission.missionGroupId
            }
          );

          this.missions = this.missions.map(item => {
            if (item.id === mission.id) {
              item.checkId = data.id;
            }

            return item;
          });
        } else {
          await this.axios.delete(
            this.namespace + `/missionChecks/${mission.checkId}`,
            {
              studentId: this.student.id,
              missionId: mission.id,
              missionGroupId: mission.missionGroupId
            }
          );
        }

        this.$store.dispatch('setToast', {
          text: 'Başarılı bir şekilde güncellendi!',
          color: 'success'
        });
      } catch (e) {
        this.$store.dispatch('setToast', {
          text: 'Güncelleme esnasında hata meydana geldi!',
          color: 'danger'
        });
        console.log(e);
      }
    },
    async getMissionList() {
      try {
        const { data } = await this.axios.get(
          this.namespace + `/missionGroups/${this.missionGroup.id}/missions`
        );

        this.missions = data;
        this.getMissionChecks();
      } catch (e) {
        console.log(e);
      }
    },
    async getMissionChecks() {
      try {
        const { data } = await this.axios.get(
          `${this.namespace}/students/${this.student.id}/missionChecks/${this.missionGroup.id}`
        );

        this.missions = this.missions.map(mission => {
          data.map(check => {
            if (check.missionId === mission.id) {
              mission.checkId = check.id;
            }
          });
          return mission;
        });
      } catch (e) {
        console.log(e);
      }
    }
  },
  watch: {
    student() {
      this.getMissionList();
    }
  }
};
</script>
